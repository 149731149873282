<template>
  <section class="section">
    <ModalNewNotification
      v-if="activeModalNewNotification"
      :active="activeModalNewNotification"
      @close-modal="activeModalNewNotification = false"
      :sendNotification="sendNotification"
      :groupList="groupList"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Notificaciones</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="control">
            <button
              class="button is-info"
              type="button"
              @click="activeModalNewNotification = true"
            >
              Enviar nueva notificación
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="notificationList"
          :loading=loading
        >
        </base-table>
      </div>
      <div class="column is-12">
        <base-pagination
          class="is-medium"
          :page="page"
          :pages="parseInt(pages)"
          :action="pagination"
          :loading="loading"
        />
      </div>
    </div>
  </section>
</template>

<script>
import mixin from '@/mixin/validateDate'
import { mapActions } from 'vuex'

export default {
  name: 'Notifications',
  mixins: [mixin],
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    ModalNewNotification: () => import('@/components/Notification/ModalNewNotification.vue')
  },
  data () {
    return {
      page: 1,
      pages: 1,
      loading: false,
      activeModalNewNotification: false,
      notificationList: [],
      groupList: [],
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        {
          id: 'title',
          header: 'Titulo',
          class: 'column-mobile'
        },
        {
          id: 'message',
          header: 'Notificación enviada',
          class: 'column-mobile'
        },
        { id: 'group_name', header: 'Grupo', class: 'column-mobile', accessor: (row) => row.group_name || 'Global' },
        { id: 'created_at', header: 'Fecha', class: 'column-mobile', accessor: (row) => this.formatDateTimestamp(row.created_at) }
      ]
    }
  },
  methods: {
    ...mapActions(['getAdminNotificationsUsers', 'sendNotificationUsers', 'getUsergroups']),
    async getNotificationList (page) {
      const resp = await this.getAdminNotificationsUsers({ page })
      if (resp.success) {
        this.notificationList = resp.payload
        this.pages = resp.totalPages
      }
    },
    async pagination (page) {
      this.page = page
      this.getNotificationList(this.page)
    },
    async sendNotification (data) {
      const resp = await this.sendNotificationUsers(data)
      if (resp.success) {
        this.activeModalNewNotification = false
        this.getNotificationList(1)
      }
    }
  },
  async beforeMount () {
    this.getNotificationList(this.page)
    const groups = await this.getUsergroups()
    this.groupList = groups
  }
}
</script>

<style lang="scss" scoped>

</style>
